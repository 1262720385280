h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $primary_font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.light,
  .light {
    font-weight: 400;
  }

  &.strong,
  .strong {
    font-weight: 700;
  }
}

h1,
h2 {
  font-weight: 600;
  line-height: 1.25em;
}

h3 {
  letter-spacing: -.625px;
  color: blue;
}

h4 {}

h5 {
  text-transform: uppercase;
  font-size: .875rem;
  color: grey;
}
