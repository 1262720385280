@charset 'utf-8';

#page-contact {
  padding-top: 3rem;
  padding-bottom: 3rem;

  article.content {
    .section {
      padding-top: 6rem;
    }

    h1 {
      font-size: 4rem;
      margin: 0 0 2.5rem 0;
    }

    .contact-block {
      margin-bottom: 1.5rem;

      @include media ('>tablet') {
        margin-left: 3rem;
      }

      h3 {
        font-size: 1rem;
        color: $blue;
        letter-spacing: 0
      }

      p {
        line-height: 1.5;
        font-size: 1.25rem;
        font-weight: 300;
      }

      .contact-item {
        display: block;
        margin: 0;

        a {
          color: $base-color;
        }
      }
    }

    .contact-map {
      display: block;
      width: 100%;
      height: 100%;
      background: $white-fade url('../../images/about/map-min.379fd08.png') center no-repeat;
    }
  }
}

#contact-form {
  padding: 3rem 4rem;
  margin: 0 2rem;
  background-color: rgba(black, 0.3);
  font-size: 1rem;

  @include media ("<tablet") {
    margin: 0 0 2rem 0;
    padding: 2rem 2rem 1rem;
    text-align: left;
  }

  .field {
    margin-bottom: 2rem;

    .label {
      color: $blue;
      font-weight: 500;

      span {
        color: $white;
        font-weight: 400;
      }
    }

    .select {
      display: block;

      option {
        color: $black;
        padding: 1rem;
      }
    }

    .input,
    .select select,
    .textarea {
      display: block;
      padding: 0;
      width: 100%;
      border: none;
      border-bottom: 1px solid rgba($white, 0.5);
      border-radius: 0;
      box-shadow: none;
      background-color: transparent;
      font-family: $primary_font;
      color: $white;
      transition: all 0.3s ease;

      &:focus {
        border-bottom: 1px solid rgba($white, 1);
      }
    }

    .button {
      color: white;
      background-color: $blue;
      text-transform: uppercase;
      font-weight: 500;
      padding: 0.75rem 1rem;
      height: auto;
    }

    .field-error {
      color: #cc3b3b;
      .close-field {
        font-size: 1rem;
        line-height: 23px;
        padding: 5px;
        cursor: pointer;
      }
    }

    &.success {
      color: #a1b69a;

    }
  }
  ::placeholder {
    color: #ffffff;
    opacity: 0.5; /* Firefox */
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #ffffff;
    opacity: 0.5;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #ffffff;
    opacity: 0.5;
  }
  // .form-group {
  //   display: block;
  //   margin-bottom: 1rem;
  // }

  // label {
  //   color: #42c2f3;
  //   margin-bottom: .5rem;
  // }

  // .form-control {
  //   display: block;
  //   width: 100%;
  //   height: calc(1.5em + .75rem + 2px);
  //   padding: .375rem .75rem;
  //   font-size: 1rem;
  //   font-weight: 400;
  //   line-height: 1.5;
  //   color: #495057;
  //   background-color: #fff;
  //   background-clip: padding-box;
  //   border: 1px solid #ced4da;
  //   border-radius: .25rem;
  //   transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  // }

  // textarea {
  //   margin: 0;
  //   overflow: auto;
  //   resize: vertical;
  // }

  // textarea.form-control {
  //   height: auto;
  // }

  // .btn {
  //   display: inline-block;
  //   font-weight: 400;
  //   color: #212529;
  //   text-align: center;
  //   vertical-align: middle;
  //   user-select: none;
  //   background-color: transparent;
  //   border: 1px solid transparent;
  //   padding: .375rem .75rem;
  //   font-size: 1rem;
  //   line-height: 1.5;
  //   border-radius: .25rem;
  //   transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  // }

  // .btn-primary {
  //   color: #fff;
  //   background-color: #42c2f3;
  //   border-color: #42c2f3;

  //   &:hover {
  //     color: #fff;
  //     background-color: #3dbaf3;
  //     border-color: #3dbaf3;
  //   }
  // }

  // [type=submit], button {
  //   -webkit-appearance: button;
  // }

  // [type=submit]:not(:disabled), button:not(:disabled) {
  //   cursor: pointer;
  // }
}
