@charset 'utf-8';

// Colors
$black         : #000000;
$base-color    : #222222;
$white         : #ffffff;
$white-fade    : #f2f2f2;
$grey-light    : #b3b3b3;
$grey          : #a6a6a6;
$grey-dark     : #999999;
$orange        : #ff7002;
$navy          : #003366;
$blue          : #42c2f3;
$green         : #00d1b2;
$toska         : #4fc5be;
$pink          : #ff3860;
$red           : #E12828;

// $primary_font  : 'Montserrat', sans-serif;
$primary_font  : 'Roboto', sans-serif;
$fa            : 'FontAwesome';
