/*!
 * Pullerits Group Stylesheet
 * Copyright 2020
 */

@import url('//fonts.googleapis.com/icon?family=Material+Icons&display=swap');
@import url('//fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,400&display=swap');
// @import url('//fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,800;1,400&display=swap');

@import '~bulma';
@import './assets/styles/vendor/normalize';
@import './assets/styles/vendor/include-media';
@import './assets/styles/vendor/swiper';

@import './assets/styles/components/variables';
@import './assets/styles/components/typography';
@import './assets/styles/components/global';
@import './assets/styles/components/header';
@import './assets/styles/components/footer';
@import './assets/styles/components/animation';
@import './assets/styles/components/video';

@import './assets/styles/pages/home';
@import './assets/styles/pages/about';
@import './assets/styles/pages/works';
@import './assets/styles/pages/contact';
