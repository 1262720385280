@charset 'utf-8';

// Route animation

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}

// Lazyload animation

.lazy {
    max-width: 100%;
    opacity: 0;
    transition: opacity .5s ease-in-out;

    &.b-loaded {
        opacity: 1;
    }
}

// Default animation

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate(-25px, 25px);
    }
    to {
        opacity: 1;
        transform: translate(0, 0);
    }
}

.anim {
    animation-name: fadeIn;

    &.in-view {
        animation-duration: 1s;
        animation-fill-mode: both;
    }
}
