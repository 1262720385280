.button-wrap {
  @include media ('<=tablet') {
    text-align: right;
  }
}

#page-works {
  h1 {
    font-size: 4rem;
    font-weight: 300;

    @include media ('<tablet') {
      font-size: 3rem;
    }
  }

  .project-item {
    position: relative;
    display: block;
    min-height: 400px;
    margin: 10% 0;
    color: $base-color;
    overflow: hidden;

    @include media ('>huge') {
      margin: 5% 0;
    }

    @include media ('<=tablet') {
      margin: 2rem 0;
      min-height: 0;
    }

    &:hover {
      .project-overview {
        .project-title {
          color: $white;

          span {
            background-color: $blue;
            box-shadow: .75rem 0 0px 0px $blue, -.75rem 0 0px 0px $blue;
          }
        }
      }

      .project-artwork-wrapper {
        .project-artwork {
          transform: scale(1.25);
        }
      }
    }

    .project-overview {
      position: relative;
      display: block;
      max-width: 30%;
      z-index: 2;

      @include media ('>wide') {
        max-width: 40%;
      }

      @include media ('<=tablet') {
        max-width: 100%;
      }

      .project-date {
        position: relative;
        display: inline-block;
        margin: 1rem 0;
        text-transform: uppercase;
        font-size: .875rem;
        color: $grey;

        @include media ('<=tablet') {
          margin: 0;
        }

        &::after {
          content: '';
          position: relative;
          top: 0;
          left: 0;
          display: block;
          margin: 1rem 0;
          height: 3px;
          width: 100px;
          background-color: $blue;

          @include media ('<=tablet') {
            margin: .5rem 0 .75rem;
          }
        }
      }

      .project-des {
        position: relative;
        max-width: 60%;
      }

      .project-title {
        min-height: 2em;
        margin: 0 0 2rem 0;
        font-weight: 500;
        font-size: 3rem;
        line-height: 1.25;
        color: $navy;
        transition: all .3s ease;

        span {
          background-color: $white;
          box-shadow: .75rem 0 0px 0px $white, -.75rem 0 0px 0px $white;
          transition: background-color .3s ease, box-shadow .3s ease;
        }

        @include media ('>wide') {
          margin-bottom: 1rem;
          min-height: 0;
          font-size: 3rem;
        }

        @include media ('<=tablet') {
          margin-bottom: 1rem;
          min-height: 0;
          font-size: 2rem;
        }
      }

      .project-category {
        list-style: none;
        margin: 0;
        padding: 0;
        color: $blue;

        @include media ('<=tablet') {
          display: none;
        }
      }

      .project-view {
        margin-top: 4rem;
        background-color: transparent;
        border: none;
        border-radius: 0;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 5px;
        cursor: pointer;
        transition: color .3s ease;

        @include media ('<=tablet') {
          display: none;
        }

        &::after {
          content: '';
          position: relative;
          display: block;
          margin-top: .5rem;
          height: 4px;
          width: 0;
          background-color: $blue;
          opacity: 0;
          transition: all .5s ease;
        }

        &:hover {
          color: $blue;

          &::after {
            width: 100%;
            background-color: $base-color;
            opacity: 1;
          }
        }
      }
    }

    .project-artwork-wrapper {
      position: absolute;
      top: 0;
      left: 25%;
      display: block;
      min-height: 360px;
      width: 75%;
      background-color: $white-fade;
      transform: translateY(-50%);
      overflow: hidden;
      z-index: 1;
      transition: height .3s ease;

      @include media ('>huge') {
        min-height: 500px;
      }

      @include media ('<=tablet') {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 200px;
        transform: translateY(0);
      }

      .project-artwork {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        min-height: 360px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transform: scale(1);
        transition: transform 2s ease;

        @include media ('>wide') {
          min-height: 500px;
        }

        @include media ('<=tablet') {
          min-height: 200px;
        }
      }
    }
  }
}

.page-work-details {
  article.content {
    .section {
      h1.title {
        margin-bottom: 2rem;
        font-size: 1.5rem;
        font-weight: 500;

        @include media ('<=tablet') {
          font-size: 1.25rem;
        }
      }

      h2.subtitle {
        font-size: 3rem;
        font-weight: 300;
        max-width: 75%;

        @include media ('<=tablet') {
          font-size: 1.5rem;
          max-width: 100%;
        }
      }

      .work-link {
        color: $grey-light;

        &:hover {
          color: $blue;
        }

        i {
          vertical-align: middle;
          font-size: 1.5rem;
          margin-left: .25rem;
        }
      }

      .content-text {
        padding-right: 4rem;

        @include media ('<=tablet') {
          padding-right: 0;
        }
      }

      p.lead {
        font-size: 1.25rem;
      }
    }

    .case {
      img {
        padding: 0 3rem;

        @include media ('<=phone') {
          padding: 0
        }
      }
    }
  }

  .app-card {
    @include media ('<=tablet') {
      // max-width: 12rem;
      display: flex;
      align-items: center;
      margin: 0 auto 2rem;

      .card-image {
        width: 30%;
      }

      figure {
        margin: 0;
        font-size: 0;
      }

      .card-content {
        padding: 1rem;

        p {
          font-size: 0.875rem;
        }
      }
    }
  }
}

#work-eckhart {
  h1.title {
    color: #1168a4
  }
}

#work-inkshuffle {
  h1.title {
    color: #3D76B6
  }

  .media-logos {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 2rem;
    width: 100%;

    .logo-wrapper {
      margin: 1rem;
      width: calc(33.333% - 2rem);
      text-align: center;

      img {
        padding: 0;
        height: 1.5rem;
        width: auto;
        object-fit: contain;
        filter: grayscale(100%);
        vertical-align: middle;

        &.np {
          height: 1.25rem;
          opacity: 0.75;
        }

        &.sun {
          height: 3rem;
          opacity: 0.75;
        }

        &.hgtv {
          height: 3rem;
        }

        &.ctv {
          height: 1.5rem;
        }

        &.steven {
          height: 2rem;
          opacity: 0.75;
        }
      }
    }
  }
}

#work-community {
  .showcase {
    img {
      margin-bottom: 20px;
    }

    .my-masonry-grid {
      display: -webkit-box; /* Not needed if autoprefixing */
      display: -ms-flexbox; /* Not needed if autoprefixing */
      display: flex;
      margin-left: -20px; /* gutter size offset */
      width: auto;
    }
    .my-masonry-grid_column {
      padding-left: 20px; /* gutter size */
      background-clip: padding-box;
    }

    /* Style your items */
    .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
      background: grey;
      margin-bottom: 20px;
    }
  }
}

#work-hirebench {
  h1.title {
    color: #8AC53F
  }
}

#work-cooler-platform {
  h1.title {
    color: #3D76B6
  }
}

#work-liivmd {
  h1.title {
    color: $orange
  }
}
