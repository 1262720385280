#page-about {
  + #site-footer {
    margin-top: 0;
  }

  article.content {
    .section {
      padding: 6rem 1.5rem;
    }
  }

  #about-prelude {
    padding-top: 10rem;
    background: $white url('../../images/about/pullerits-icon-dots.668f551.png') top left 25% no-repeat;
    background-size: 35%;

    @include media ('<tablet') {
      background-size: 100%;
    }

    h2 {
      font-size: 4.5rem;
      font-weight: 300;
      color: $grey;
      line-height: 1;
      text-align: right;

      @include media ('<tablet') {
        font-size: 2.5rem;
      }
    }
  }

  #about-hero {
    min-height: 500px;
    height: 40vh;
    background-image: url('../../images/about/about.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include media ('<tablet') {
      min-height: 200px;
    }
  }

  #about-main {
    background-color: $white;
    padding-bottom: 8rem;

    h3 {
      font-size: 1.25rem;
      color: $blue;
      margin-bottom: 1.5rem;
    }

    p {
      font-weight: 400;
      font-size: 1.125rem;
    }

    .quote {
      position: relative;
      font-weight: 600;
      font-size: 2rem;
      line-height: 1.25;
      margin-top: 2rem;
      margin-left: 4rem;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -4rem;
        display: inline-block;
        height: 3rem;
        width: 3rem;
        background: transparent url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgNTMuODEiPjx0aXRsZT5xdW90ZTwvdGl0bGU+PHBhdGggZD0iTTMwLjEzLDUzLjgxSDBMMjguMjIsMGgyM1oiIGZpbGw9IiM0MmMyZjMiLz48cGF0aCBkPSJNNzksNTMuODFINDguODJMNzcsMGgyM1oiIGZpbGw9IiM0MmMyZjMiLz48L3N2Zz4=') center no-repeat;
        background-size: contain;
      }
    }
  }

  #about-founder {
    margin-top: -5rem;
    padding-top: 25rem;
    background: transparent url('../../images/about/matthew-pullerits-h.590265b.jpg') center top no-repeat;
    background-size: cover;
    color: $white;
    text-shadow: 1px 1px 2px rgba($base-color, .5);
    transition: all .3s ease;

    @include media ('>=1920px') {
      padding-top: 35rem;
    }

    h3 {
      color: $blue;
    }
  }

  #about-services {
    background-color: #eaf8fe;

    h2 {
      font-weight: 500;
      color: $navy;
      font: 3rem;
      margin-bottom: 2rem;
    }

    .service-items {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .service-item {
      height: 6rem;
      width: 30%;
      overflow: hidden;

      @include media ('<tablet') {
        width: 100%;
        line-height: 1.5;
      }

      &:hover {
        color: $blue;

        &::before {
          width: 4px;
        }
      }

      .num {
        display: inline-block;
        height: 6rem;
        width: 10%;
        line-height: 6rem;
        text-align: right;
        font-weight: 600;
        color: rgba($blue, .5);
        vertical-align: middle;
      }

      .line {
        display: inline-block;
        margin: 0 1rem;
        width: 20%;
        height: 1px;
        background: $navy;
        opacity: .2;
        vertical-align: middle;
      }

      .text {
        display: inline-block;
        width: calc(70% - 2rem);
        font-size: 1.25rem;
        font-weight: 500;
        color: $navy;
        transition: color 0.5s ease;
        vertical-align: middle;
      }
    }
  }

  #about-work {
    padding-top: 6rem;
    padding-bottom: 6rem;
    color: $white;
    text-align: center;
    background: $blue url('../../images/about/about-work.165be58.jpg') center no-repeat;
    background-size: cover;

    h3 {
      font-size: 2.5rem;
      font-weight: 400;
      color: $white;
    }

    a.button {
      margin: 2rem 0 1rem;
      padding: 1.325rem 3rem 1.25rem;
      border-radius: 2em;
      text-transform: uppercase;
      font-family: $primary_font;
      line-height: 1;
      transition: all .3s ease-out;

      &:hover {
        color: $navy;
        font-weight: 600;
        box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, .5);
      }
    }
  }
}
