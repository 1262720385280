@charset 'utf-8';

#site-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: block;
  padding: 0.5rem 0;
  border-bottom: 1px solid $white-fade;
  background-color: $white;
  z-index: 4;

  @include media('<tablet') {
    position: fixed;
    height: 75px;
    border-bottom: 1px solid $white-fade;
    background-color: $white;
    z-index: 4;
  }

  .is-flex {
    display: flex;
    align-items: center;
    height: 50px;
  }

  .site-logo {
    display: inline-block;
    height: 2.5rem;
    vertical-align: middle;

    @include media('>tablet', '<desktop') {
      margin-left: 1.5rem;
    }

    @include media('<tablet') {
      position: fixed;
      top: 1rem;
      right: 4.5rem;
    }
  }

  .site-navigation {
    display: inline-block;
    z-index: 3;

    @include media('>tablet', '<desktop') {
      margin-right: 1.5rem;
    }

    a {
      display: inline-block;
      margin: 0 .75rem;
      font-size: 1.125rem;
      text-align: right !important;

      @include media('<tablet') {
        display: block;
        margin: 0.5rem 0;
        font-size: 1.5rem;
      }

      &.router-link-exact-active,
      &.router-link-active {
        color: $navy;
        font-weight: 600;

        @include media ("<tablet") {
          color: $white;
        }
      }
    }
  }

  .nav-wrapper {
    @include media('<tablet') {
      position: fixed;
      top: 0;
      right: -100vw;
      display: block;
      height: 100vh;
      width: 100vw;
      z-index: 5;

      .menu-active & {
        right: 0;
      }
    }

    .nav-container {
      @include media('<tablet') {
        position: relative;
        top: 0;
        right: 0;
        display: block;
        padding: 5rem 1.5rem 3rem;
        height: 100vh;
        width: 90vw;
        background-color: $navy;
        color: $white;
        z-index: 2;
        transform: translateZ(0) translateX(200vw);
        transition: all .5s ease;

        .menu-active & {
          transform: translateZ(0) translateX(10vw);
        }
      }

      .nav-header {
        display: none;

        @include media('<tablet') {
          .menu-active & {
            display: block;
            position: absolute;
            top: 24px;
            left: 30px;
            width: 50%;

            svg.nav-logo {
              display: block;
              max-width: 75%;

              * {
                fill: #fff
              }
            }
          }
        }
      }

      .nav-content {
        transition: all .3s ease;

        @include media('<tablet') {
          .menu-active & {
            // position: absolute;
            // bottom: 6rem;
            // right: 2rem;
          }

          .site-navigation {
            position: relative;
            top: auto;
            right: auto;
            display: block;

            span {
              a {
                display: block;
                font-size: 1.5rem;
                margin: .5rem 0;
                color: $white;

                &.router-link-exact-active {
                  color: $blue;
                }
              }
            }
          }
        }
      }

      .nav-contact {
        display: none;
        margin-top: 2rem;
        text-align: right;

        @include media('<tablet') {
          display: block;
        }

        p {
          margin: 1rem 0;
        }

        strong {
          display: inline-block;
          margin-right: 1rem;
          font-weight: 500;
          color: $blue;
        }

        a {
          color: white;
        }
      }

      .nav-footer {
        display: none;

        @include media('<tablet') {
          position: absolute;
          right: 2rem;
          bottom: 2rem;
          font-size: 0.75rem;

          .menu-active & {
            display: block;

            span {
              opacity: .5;
            }
          }
        }
      }
    }

    .nav-overlay {
      position: fixed;
      top: 0;
      left: -100vw;
      height: 0;
      width: 0;
      background-color: rgba($black, 0);
      opacity: 0;
      z-index: -1;
      transition: opacity 1s ease;

      @include media('<tablet') {
        .menu-active & {
          left: 0;
          height: 100vh;
          width: 100vw;
          background-color: rgba($black, .8);
          opacity: 1;
          z-index: 1;
        }
      }
    }
  }

  .material-icons {
    font-size: 2.5rem;
    color: $blue;

    &.is-mobile {
      display: none;
      z-index: 3;

      @include media ('<tablet') {
        display: inline-block;
        cursor: pointer;
      }
    }

    &.menu {
      position: fixed;
      right: 1rem;
      top: 1rem;
    }

    &.contact {
      position: fixed;
      right: 1rem;
      top: 1rem;
    }

    &.toggle-close {
      position: fixed;
      right: 1rem;
      top: 1rem;
      display: none;
      z-index: 6;
      color: $white;

      @include media ('<tablet') {
        .menu-active & {
          display: inline-block;
        }
      }
    }
  }
}
