@charset 'utf-8';

#page-home {
  position: relative;
  z-index: 3;
  overflow-x: hidden;

  #intro {
    background-color: white;

    .player-wrapper {
      position: relative;
      padding-top: 56.25%;

      .video-player {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  #approach {
    background-color: white;

    h2 {
      margin-bottom: 3rem;
      font-weight: 300;
      font-size: 3rem;
      text-align: center;
      color: $navy;

      @include media ("<tablet") {
        font-size: 2rem;
      }

      span {
        color: $blue;
      }
    }

    .section-wrapper {
      display: flex;
      justify-content: space-between;

      @include media ("<tablet") {
        flex-wrap: wrap;
      }

      .section-image {
        min-width: 40%;
        background-color: $grey;
        background-image: url('https://images.unsplash.com/photo-1552664688-cf412ec27db2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @include media ("<tablet") {
          min-width: 100%;
          min-height: 15rem;
        }
      }

      .section-content {
        border-top: 1px solid #ebebeb;
        padding: 6rem;

        @include media ("<tablet") {
          padding: 2rem 1.5rem;
        }
      }
    }

    .approach-list {
      img {
        display: block;
        margin: 0 auto 2rem;
        width: 6rem;
      }

      h4 {
        text-align: center;
        color: $navy;
        font-weight: 400;
      }

      p {
        padding-left: 1rem;
        padding-right: 1rem;
        margin-bottom: 2rem;
        text-align: center;
        // text-align: justify;
        // text-align-last: center;

        @include media ("<tablet") {
          margin-top: 2rem;
        }
      }
    }
  }

  #works {
    background-color: white;

    .slide-container {
      position: relative;
      display: block;
      margin: 0 auto;
      min-height: 75vh;
      width: 75%;

      @include media ('<=tablet') {
        width: 100%;
        overflow: hidden;
      }

      .slide-text {
        position: absolute;
        top: 50%;
        display: block;
        width: 50%;
        transform: translateY(-50%);
        z-index: 2;

        @include media ('<=tablet') {
          position: relative;
          top: 0;
          transform: translateY(0);
          margin-top: 2rem;
          padding: 0 2rem;
          width: calc(100% - 4rem);
        }

        h1 {
          position: relative;
          display: block;
          margin: 0;
          font-size: 3.5rem;
          line-height: 1;
          z-index: 2;

          @include media ('<=tablet') {
            font-size: 2rem;
          }
        }

        h2 {
          font-weight: 600;
          font-size: 1.5rem;
        }

        .button {
          height: auto;
          margin: 2rem 0 1rem;
          padding: 1.125rem 2rem;
          border-radius: 4em;
          text-transform: uppercase;
          font-family: $primary_font;
          line-height: 1;
          transition: all .3s ease-out;
          vertical-align: middle;

          @include media ('<=tablet') {
            padding: 1rem 2rem;
            font-size: .875rem;
          }

          &:hover {
            background-color: $navy;
            box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, .25);
          }
        }
      }

      .slide-artwork-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        min-width: 400px;
        width: 50%;
        z-index: 1;
        transform: translateY(-50%);

        @include media ('<=tablet') {
          top: auto;
          left: 0;
          right: 0;
          bottom: 0;
          margin: 1rem auto 0;
          width: 100%;
          transform: translateY(0);
        }

        &::before {
          content: '';
          position: absolute;
          top: -50%;
          right: -15%;
          display: block;
          height: 200%;
          width: 75%;
          opacity: .5;
          background-color: $blue;
          z-index: -1;
          transform: skew(-10deg);
        }

        .slide-artwork {
          position: relative;
          display: block;
          min-height: 500px;
          width: 100%;
          background: $blue center no-repeat;
          background-size: cover;

          @include media ('<=tablet') {
            min-height: 220px;
          }
        }
      }

      &.slide-intro {
        width: 100%;

        h1 {
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          display: block;
          margin: auto;
          width: 50%;
          font-size: 4rem;
          font-weight: 600;
          line-height: 1;
          color: $white;
          text-align: center;
          vertical-align: middle;
          transform: translateY(-50%);
          z-index: 2;

          @include media ('<=tablet') {
            font-size: 2rem;
            width: 80%;
          }
        }

        canvas {
          position: absolute;
          right: 0;
          left: 0;
          bottom: 0;
          display: block;
          margin: 0 auto;
          height: 100%;
          width: 100%;
          z-index: 1;
          vertical-align: bottom;
        }
      }
    }
  }

  #solutions {
    background-color: white;
    padding-top: 6rem;
    padding-bottom: 6rem;

    @include media ("<tablet") {
      padding: 2rem 0;
    }

    .solution-heading {
      text-align: center;

      @include media ("<tablet") {
        text-align: left;
        padding: 0 1.5rem;
      }

      .title {
        margin-bottom: 2rem;
        font-weight: 300;
        font-size: 3rem;
        color: $blue;

        @include media ("<tablet") {
          font-size: 2rem;
          font-weight: 400;
        }

        + .subtitle {
          margin-bottom: 3rem;
          font-size: 1.75rem;
          font-weight: 300;
          color: #666;

          @include media ("<tablet") {
            font-size: 1.125rem;
            margin-bottom: 1rem;
          }
        }
      }
    }

    .solution-item {
      margin-bottom: 2rem;

      .image {
        min-height: 380px;
        margin-bottom: 1.5rem;
        border-radius: 0.25rem;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-blend-mode: luminosity;
        background-color: $navy;

        @include media ("<tablet") {
          min-height: 200px;
        }
      }

      .title {
        padding: 0 1.5rem;
        margin-bottom: 1rem;
        line-height: 1.25;
        font-weight: 500;
        font-size: 1.25rem;
        color: $navy;

        @include media ("<tablet") {
          min-height: 0;
        }
      }

      .desc {
        padding: 0 1.5rem;
        font-size: 1.125rem;
        font-weight: 300;
        color: $base-color;

        @include media ("<tablet") {
          min-height: 0;
          font-size: 1rem;
        }
      }
    }
  }

  .swiper-slide {
    min-height: 100%;
    background: $white-fade url('../../images/about/worn-dots.0d6a370.png') center;
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: 1.5rem;
  }

  .swiper-pagination-bullet {
    height: 6px;
    width: 40px;
    border-radius: 5px;
  }

  @include media ('>tablet') {
    .is-mobile {
      display: none;

      .SuperHero-container {
        display: flex;
        align-items: center;
        padding-top: 0;
        padding-right: 60px;
        padding-left: 60px;
        box-sizing: border-box;
        z-index: 0;
      }
    }

    .VideoHero {
      position: relative;
      width: 100%;
      overflow: hidden;

      .SuperHero-content {
        position: relative;
        z-index: 3;
        width: 100%;
        overflow: visible;
      }

      .VideoHero-overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 2;
        width: 100%;
        overflow: hidden;
      }
    }

    .SuperHero-strapline {
      margin: 4.625em 0;
      max-width: 580px;
    }

    .jquery-background-video-wrapper {
      position: relative;
      overflow: hidden;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .video-hero-center {
      position: absolute !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @include media ('<tablet') {
    .is-desktop {
      display: none;
    }

    .TextBlock-item {
      h1, h3 {
        text-align: center;
      }
    }
  }

  .bg-location::after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background: #eef6fc;
    /*opacity: 0.8;*/
    z-index: -1;
    top: 0;
    bottom: 0;
  }

  .summary-about {
    max-width: 100%;
    padding: 0;
    position: relative;

    h1 {
      color: #1d72aa;
      font-size: 1.75em;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .summary-strapline {
    h3 {
      color: #1d72aa;
      font-size: 1.095rem;
      padding: 1.5rem;
      line-height: 1.5;
      text-align: left;
    }
  }

  .summary-content {
    padding: 1.5rem;
    margin: auto;

    @include media ('>wide') {
      max-width: 1152px;
      width: 1152px;
      margin: auto;
    }
  }
}
