@charset 'utf-8';

#contact-page + #site-footer {
  min-height: calc(100vh - 66px);
}

#site-footer {
  position: relative;
  color: $grey-light;

  @include media ('<=tablet') {
    text-align: center;
  }

  > .section {
    background-color: transparent;
    z-index: 2;
  }

  #footer-top {
    padding-top: 6rem;
    color: $white;

    .contact-content {
      margin-left: 2rem;
      margin-right: 4rem;
      text-align: right;

      @include media ("<tablet") {
        margin-right: 0;
        margin-left: 0;
        text-align: center;
      }

      h6 {
        color: $blue;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.5rem;
      }

      h5 {
        margin-bottom: 1rem;
        font-size: 2rem;
        font-weight: 500;
        color: #fff;

        @include media ("<tablet") {
          font-size: 1.5rem;
        }
      }

      p {
        font-size: 1.25rem;
        line-height: 1.5;
      }

      strong {
        display: inline-block;
        margin-right: 0.75rem;
        color: $blue;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1rem;
        vertical-align: middle;
      }

      a, span {
        color: $white;
        font-size: 1.5rem;
        vertical-align: middle;
      }
    }
  }

  #footer-bottom {
    padding: 3rem;

    .footer-logo {
      width: 2rem;
      color: $white-fade;
      transition: color .5s ease;
      vertical-align: middle;

      @include media ('<=tablet') {
        margin: 2rem auto;
      }

      &:hover {
        color: $blue;
      }
    }

    .footer-menu {
      text-align: right;

      @include media ('<=tablet') {
        display: none;
      }

      a {
        color: $grey-light;
        margin-left: 1.5rem;

        @include media ('<=tablet') {
          margin: 0 1rem
        }

        &:hover {
          color: $blue;
        }
      }
    }
  }
}
