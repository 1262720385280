.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  background-color: black;
  z-index: 1;
  overflow: hidden;

  @include media ("<tablet") {
    position: fixed;
    z-index: -1;
    height: 100vh;
  }
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  opacity: 0.5;

  @include media ('<tablet') {
    margin-bottom: 3rem;
  }
}

.video {
  width: 100%;
  object-fit: cover;
}

.full-screen-icon {
  position: absolute;
  width: 24px;
  height: 24px;
  bottom: 1.5rem;
  right: 1.5rem;
  z-index: 99;
  filter: contrast(0.5);
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    filter: invert(1);
  }
}

.modal-video {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, 1);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: .3s;
  animation-name: modal-video;
  -webkit-transition: opacity .3s ease-out;
  -moz-transition: opacity .3s ease-out;
  -ms-transition: opacity .3s ease-out;
  -o-transition: opacity .3s ease-out;
  transition: opacity .3s ease-out;
}

/* Modal Content/Box */
.modal-video-content {
  background-color: #000;
  padding-top: 20px;
  padding-bottom: 145px;
  width: 100%;
  height: 100%;
  margin-top: 70px;
  position: relative;

  /* The Close Button */
  .close {
    font-weight: bold;
    position: absolute;
    background: #000;
    color: #fff;
    cursor: pointer;
    top: 10px;
    right: 25px;
    width: 50px;
    height: 50px;
    border: 2px solid rgba(99,96,96,0.6);
    border-radius: 100%;

    z-index: 1;

    &:before, &:after {
      position: absolute;
      left: 22px;
      top: 13px;
      content: ' ';
      height: 20px;
      width: 2px;
      background-color: #fff;
    }

    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }

    &:hover, &:focus {
      color: #000;
      background-color: #fff;
      cursor: pointer;
      border: transparent;

      &:before, &:after {
        left: 24px;
        top: 15px;
        background-color: #000;
      }
    }
  }
}

.modal-video-player {
  position: relative;
  video {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
  }
}

@keyframes modal-video {
  from { opacity:0 }
  to { opacity:1 }
}
