html {
  box-sizing: border-box;
  transition: font-size .3s ease;

  @include media (">huge") {
    font-size: 21px;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: $primary_font;

  &::-webkit-scrollbar {
    height: .675rem;
    width: .675rem;
    background-color: #ebebeb;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $blue;
  }
}

#root.menu-active {
  min-height: 100%;
  height: 100vh;
  overflow: hidden;
}

#site-header + main {
  padding-top: 66px;

  @include media ("<tablet") {
    padding-top: 75px;
  }
}

.container {
  @media screen and (min-width: 1921px) {
    max-width: 1650px;
    width: 1650px;
  }
}

section {
  position: relative;
  display: block;
  overflow: hidden;

  &.section {
    background-color: $white;
    z-index: 1;

    @include media ('<tablet') {
      padding: 1rem;
    }
  }

  &.no-padding {
    padding: 0;
  }

  &.narrow {
    padding: 1rem;
  }

  &.border-top {
    border-top: 1px solid #ebebeb;
  }

  &.border-bottom {
    border-bottom: 1px solid #ebebeb;
  }
}

a {
  text-decoration: none;
  color: $blue;
  transition: color .2s ease;
}

.content {
  h1, h2, h3, h4, h5 {
    font-weight: 300;
  }
}

p {
  font-family: $primary_font;
  font-size: 1rem;
  line-height: 1.5;

  &.light,
  .light {
    font-weight: 300;
  }

  &.small {
    font-size: .75em;
  }

  &.strong,
  .strong {
    font-family: 600;
  }
}

.uppercase {
  text-transform: uppercase;
}

ul {
  li {
    line-height: 1.5;
  }

  &.menu.simple {
    li {
      display: inline-block;
    }
  }

  &.small {
    li {
      font-size: .875rem;
    }
  }

  &.list-checkmark {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      padding-left: 2em;

      &::before {
        content: '\f00c';
        position: absolute;
        display: inline-block;
        margin-left: -1.5em;
        width: 1em;
        font-family: $fa;
        color: grey;
      }
    }
  }
}

svg {
  max-width: 100%;
  height: auto;

  &.svgref {
    position: absolute;
    width: 0;
    height: 0;
    font-size: 0;
    visibility: hidden;
  }
}

button,
.button {
  font-family: $primary_font;
  border-radius: .25rem;

  &.primary {
    color: white;

    &:hover {
      color: white;
    }
  }

  &.secondary {
    background-color: blue;
    color: white;

    &:hover {
      color: white;
    }
  }

  &.arrow {
    background-color: transparent;
    background-image: svg-url('<svg version="1.1" id="arrow" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve"><polygon fill="#FF7102" points="59.09,200 40.91,181.82 122.72,100 40.91,18.18 59.09,0 159.09,100 "/></svg>');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}

.ml-auto {
  margin-left: auto;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.clearfix,
.clear {
  zoom: 1;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}
